import moment from 'moment'

const VAT = (price) => {
  let p = parseFloat(price)
  return {
      vat: (p * 0.2).toFixed(2),
      total: (p + p * 0.2).toFixed(2)
  }
}

const parseDescription = (description) => {
  let parts = description.replace('\n\n', '\n').split('\n')
  let payload = `${parts[1]} ${parts[2] ? `<br>${parts[2]}` : ''}` 
  let length = parts[0].split('x')[0].replace('L: ', '').trim()
  let width = parts[0].split('x')[1].replace('W: ', '').trim()
  let height = parts[0].split('x')[2].replace('H: ', '').trim()

  return {
      length,
      width,
      height,
      payload
  }
}


const getPickupDetails = (pickup) => {

  if (pickup.time_range == "AT") {
    return {
      time: `At ${moment(pickup.ready_at).format("HH:mm")}`,
      date: `${moment(pickup.ready_at).format('dddd DD MMM YYYY')}`
    }
  } else if (pickup.time_range == "BETWEEN") {
    return {
      time: `Between ${moment(pickup.ready_at).format("HH:mm")} - ${moment(pickup.collect_by).format("HH:mm")}`,
      date: `${moment(pickup.ready_at).format('dddd DD MMM YYYY')}`
    }
  } else if (pickup.time_range == "BEFORE") {
    return {
      time: `By ${moment(pickup.collect_by).format("HH:mm")}`,
      date: `${moment(pickup.collect_by).format('dddd DD MMM YYYY')}`
    }
  }
}

const getDropoffDetails = (dropoff) => {

  if (dropoff.time_range == "AT") {
    return {
      time: `At ${moment(dropoff.deliver_from).format("HH:mm")}`,
      date: `${moment(dropoff.deliver_from).format('dddd DD MMM YYYY')}`
    }
  } else if (dropoff.time_range == "BETWEEN") {
    return {
      time: `Between ${moment(dropoff.deliver_from).format("HH:mm")} - ${moment(dropoff.deliver_by).format("HH:mm")}`,
      date: `${moment(dropoff.deliver_from).format('dddd DD MMM YYYY')}`
    }
  } else if (dropoff.time_range == "BY") {
    return {
      time: `By ${moment(dropoff.deliver_by).format("HH:mm")}`,
      date: `${moment(dropoff.deliver_by).format('dddd DD MMM YYYY')}`
    }
  } else if (dropoff.time_range == "ASAP") {
    return {
      time: `ASAP`,
      date: `${moment(dropoff.deliver_from).format('dddd DD MMM YYYY')}`
    }
  }

}

const getAddress = (address_object) => {
  
  const { address_components, formatted_address } = address_object 

  let address = {
    address_1: "",
    city: "",
    postcode: "", 
    formatted_address
  }

  address_components.map(({ short_name, types }) => {
    if(types.includes("postal_code")) address.postcode = short_name
    if(types.includes("postal_town")) address.city = short_name
    if(types.includes("route")) address.address_1 = short_name
  })

  return address
}

export {
  VAT, 
  parseDescription,
  getPickupDetails,
  getDropoffDetails,
  getAddress
}