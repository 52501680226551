import React  from 'react'
import moment from 'moment'

import ProgressIcon1 from './progress-icon-1.svg'
import ProgressIcon2 from './progress-icon-2.svg'
import ProgressIcon3 from './progress-icon-3.svg'

const QuoteInfo = ({ quote, company }) => {

  return (
    <React.Fragment>
      <section className="dark-box md:hidden mx-6 px-4 py-3 mt-5 rounded-md">
          <div className="flex items-center">
              {/* <img className="mr-3" src="http://we-are-same-day.local/wp-content/themes/jyst-theme/media/green-clock.svg" /> */}
              {/* <p className="text-green-primary font-medium">Collection within 1 hour available</p> */}
          </div>
          
          <div className="flex justify-between mt-4">
              <div className="">
                  <h5 className="text-lg text-white">Prices from:</h5>
                  <p className="text-sm gray-text">Scroll down to see all prices</p>
              </div>
              <div className="">
                  <h5 className="font-medium text-lg text-white">£{ quote.quotes[0].price} + VAT</h5>
              </div>
          </div>
      </section>

      <section className="block md:hidden px-6 mt-7">
          <h5 className="text-xl font-bold">Job Details:</h5>
          <div className="flex mt-2">
              <span className="mr-10">Collection from</span>
              <span className="font-medium">{ quote.booking.pickups[0].address.postcode }</span>
          </div>
          <div className="flex">
              <span className="mr-10">Destination</span>
              <span className="font-medium">{ quote.booking.dropoffs[quote.booking.dropoffs.length -1].address.postcode }</span>
          </div>
          <div className="flex">
              <span className="mr-10">Est Transit Time</span>
              <span className="font-medium">{ quote.journey_time ? quote.journey_time : '--' }</span>
          </div>
          <div className="flex">
              <span className="mr-10">Earliest Delivery</span>
              <span className="font-medium">{ quote.earliest_delivery ? `${moment(quote.earliest_delivery).utc().format("HH:mm")} If Booked Now` : '--' }</span>
          </div>
          <a className="text-green-primary block mt-2 ml-2" href="/">Edit</a>
      </section>

      <section className="custom-progress-container mx-auto mt-4 py-4 rounded-lg hidden md:flex items-center justify-between custom-progress-details mx-6">

          <div className="lg:flex w-4/5 justify-around">
              <div className="flex items-center md:px-4 py-1 lg:py-2 lg:py-0">
                  <img src={ProgressIcon1} className="mr-3" />
                  <h6 className="text-white text-md font-light">Pickup from: <br/>
                  <span className="font-bold">{ quote.booking.pickups[0].address.postcode }</span></h6>
              </div>
              <div className="flex items-center md:px-4 py-1 lg:py-2 lg:py-0">
                  <img src={ProgressIcon1} className="mr-3" />
                  <h6 className="text-white text-md font-light">Destination: <br/>
                  <span className="font-bold">{ quote.booking.dropoffs[quote.booking.dropoffs.length -1].address.postcode }</span></h6>
              </div>
              <div className="flex items-center md:px-4 py-1 lg:py-2 lg:py-0">
                  <img src={ProgressIcon2} className="mr-3" />
                  <h6 className="text-white text-md font-light">Earliest Delivery: <br/>
                  <span className="font-bold">{ quote.earliest_delivery ? `${moment(quote.earliest_delivery).utc().format("HH:mm")} if Booked Now` : '--' }</span></h6>
              </div>
              <div className="flex items-center md:px-4 py-1 lg:py-2 lg:py-0">
                  <img src={ProgressIcon3} className="mr-3" />
                  <h6 className="text-white text-md font-light">Est Transit Time: <br/>
                  <span className="font-bold">{ quote.journey_time ? quote.journey_time : '--' }</span></h6>
              </div>
          </div>
          
        <a href={company.domain} className="text-white text-lg font-bold mr-3 link md:px-4" >« Back</a>

      </section>
    </React.Fragment>
  )
}

export default QuoteInfo;