import React, { useState, useEffect } from 'react'
import axios from 'axios'
import cardsIcon from './cards.svg'
import stripeIcon from './stripe.svg'
import deliveryIcon from './delivery-icon.svg'
import lineIcon from './line-icon.svg'
import { loadStripe } from '@stripe/stripe-js'
import { VAT, parseDescription, getPickupDetails, getDropoffDetails } from '../../utils'
import parsePhoneNumberFromString from 'libphonenumber-js';

const PagePayment = ({ company, quote, total, out_of_hours }) => {
  const [tos, setTos] = useState(false)
  const [loadingNotice, setLoadingNotice] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [return_journey, setReturnJourney] = useState(false)
  const [pickups, setPickups] = useState([])
  const [dropoffs, setDropoffs] = useState([])

  const [tempTotal, setTempTotal] = useState(0)

  const [errors, setErrors] = useState([])

  const [contact, setContact] = useState({
    name: { first: "", last: "" }, 
    notifications: {},
    contact_details: {}
  })

  const [details, setDetails] = useState({

  })

  useEffect(() => {
    const stripePromise = loadStripe(company.stripe_publishable_key)
    const { booking: { pickups, dropoffs, details }, contact, return_journey, quote: { price } } = quote
    
    setReturnJourney(return_journey)

    setPickups(pickups)
    setDropoffs(dropoffs)

    setContact(contact)
    setDetails(details)
  }, [])

  const updateQuote = () => {
    setUpdating(true)

    if(validateForm()) {
      setUpdating(false)
      return 
    }

    axios.put(`/quote/quotes/${quote.id}`, {
      quote: {
        ...quote, 
        return_journey,
        contact, 
        booking: {
          details,
          pickups,
          dropoffs
        }
      }
    }).then((response) => {
      
      axios.post(`/quote/quotes/${quote.id}/create_checkout_session`, {
        quote: quote.id
      }).then((response) => {
        const { id } = response.data 
        var stripe = Stripe(company.stripe_publishable_key)
        stripe.redirectToCheckout({
          sessionId: id
        })
        setUpdating(false)
      })

    })

  }
  const validateForm = () => {
    let e = []
    const telRegex = /^[+]?[\d ()-]{8,}([eE]?[xX][tT]?[nN]?[\d ()-]+)?$/;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!tos) e.push("T&Cs must be accepted.")
    if (!loadingNotice) e.push("Loading notice must be accepted.")

    // validate contact email
    if(!emailRegex.test(contact.contact_details.email)) {
      e.push("Your Details - Email Address is invalid!")
    }

    // validate contact phone number
    if(!telRegex.test(contact.contact_details.phone)) {
      e.push("Your Details - Telephone number is invalid!")
    }

    // validate Collection phone number
    let p = pickups.filter((pickup) => !telRegex.test(pickup.contact.contact_details.phone))
    
    if (p.length > 0) {
      e.push("Collection Address - Contact Telephone is invalid!")
    }
    
    let d = dropoffs.filter((dropoff) => !telRegex.test(dropoff.contact.contact_details.phone))
    
    if (d.length > 0) {
      e.push("Delivery Address - Contact Telephone is invalid!")
    }

    // validate required fields
    if (requiredFields()) {
      e.push("All fields marked with * required!")
    }
    setErrors(e)
    return e.length > 0
  }

  const requiredFields = () => {

    let invalidPickups = pickups.filter((pickup) => pickup.address.address_1 === "" || pickup.address.city === "" || pickup.contact.name.first === "" || pickup.contact.name.last === "" || pickup.contact.contact_details.phone === "")
    if (invalidPickups.length > 0) return true
    
    let invalidDropoffs = dropoffs.filter((dropoff) => dropoff.address.address_1 === "" || dropoff.address.city === "" || dropoff.contact.name.first === "" || dropoff.contact.name.last === "" || dropoff.contact.contact_details.phone === "")
    if (invalidDropoffs.length > 0) return true

    return (contact.name.first === "" || contact.name.last === "" || contact.contact_details.email === "" 
            || contact.contact_details.phone === "")
  }

  const getTotal = () => {
    if (quote) {
      if (return_journey) {
        let base = parseFloat(total.quote_price) + (parseFloat(total.return_journey_price)) + parseFloat(total.out_of_hours_price)
        return base + (base * 0.2)
      } else {
        let base = parseFloat(total.quote_price) + parseFloat(total.out_of_hours_price)
        return base + (base * 0.2)
      }
    } 
    return 0
  }

  return (
    <React.Fragment>
      <section className="custom-progress-2-container md:flex flex-row-reverse md:flex-row justify-between">
        <div className="left-side">
            <h2 className="text-xl font-medium mb-4"> Your details: </h2>
            <form>
                <div className="sm:flex custom-row">
                    <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
                        <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span></label>
                        <input 
                          type="text" 
                          maxlength="20"
                          value={contact.name.first} 
                          onChange={(e) => setContact({...contact, name: { ...contact.name, first: e.target.value }})} 
                          className="text-input rounded-md" 
                          autoComplete="off"
                          onFocus={(e) => {
                            e.target.autocomplete = "contact-name-first"
                          }}
                        />
                        
                    </div>
                    <div className="flex flex-col sm:w-1/2 sm:ml-3">
                        <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span></label>
                        <input 
                          type="text" 
                          maxlength="20"
                          value={contact.name.last}
                          onChange={(e) => setContact({...contact, name: { ...contact.name, last: e.target.value }})} 
                          className="text-input rounded-md" 
                          autoComplete="off"
                          onFocus={(e) => {
                            e.target.autocomplete = "contact-name-last"
                          }}
                        />
                    </div>
                </div>
                <div className="custom-row">
                    <div className="flex flex-col">
                        <label className="title font-light mb-0.5">Company </label>
                        <input 
                          value={contact.company} 
                          onChange={(e) => setContact({...contact, company: e.target.value})} 
                          type="text"
                          maxlength="60"
                          className="text-input rounded-md" 
                          autoComplete="off"
                          onFocus={(e) => {
                            e.target.autocomplete = "contact-company"
                          }}
                        />
                    </div>
                </div>
                <div className="sm:flex custom-row">
                    <div className="flex flex-col w-full sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
                        <label className="title font-light mb-0.5">Email <span className="text-red-500">*</span></label>
                        <input 
                          type="email"
                          maxlength="255" 
                          value={contact.contact_details.email}
                          onChange={(e) => setContact({...contact, contact_details: {...contact.contact_details, email: e.target.value}})}
                          className="text-input rounded-md" 
                          autoComplete="off"
                          onFocus={(e) => {
                            e.target.autocomplete = "contact-details-email"
                          }}
                        />
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 sm:ml-3">
                        <label className="title font-light mb-0.5">Telephone number <span className="text-red-500">*</span></label>
                        <input 
                          type="tel"
                          maxlength="20"
                          min="0"
                          value={contact.contact_details.phone}
                          onChange={(e) => setContact({...contact, contact_details: {...contact.contact_details, phone: e.target.value}})}
                          className="text-input rounded-md" 
                          autoComplete="off"
                          onFocus={(e) => {
                            e.target.autocomplete = "contact-details-phone"
                          }}
                        />
                    </div>
                </div>
                <div className="custom-row">
                    <label className="title font-light mb-0.5">Notifications</label>
                    <div className="multiselect">
                        <div>
                          <label htmlFor="contact-notifications-booking">
                              <input type="checkbox" id="contact-notifications-booking" 
                                checked={contact.notifications.booking_confirmation} 
                                onChange={(e) => {
                                  setContact({
                                    ...contact, 
                                    notifications: {
                                      ...contact.notifications,
                                      booking_confirmation: e.target.checked
                                    }
                                  })
                                }}
                              /> Booking confirmation
                          </label>
                          <label htmlFor="contact-notifications-cancelled">
                              <input type="checkbox" id="contact-notifications-cancelled" 
                              checked={contact.notifications.booking_cancelled} 
                              onChange={(e) => {
                                setContact({
                                  ...contact, 
                                  notifications: {
                                    ...contact.notifications,
                                    booking_cancelled: e.target.checked
                                  }
                                })
                              }}
                            /> Booking Updated
                          </label>
                          <label htmlFor="contact-notifications-changed">
                              <input type="checkbox" id="contact-notifications-updated" 
                              checked={contact.notifications.booking_updated} 
                              onChange={(e) => {
                                setContact({
                                  ...contact, 
                                  notifications: {
                                    ...contact.notifications,
                                    booking_updated: e.target.checked
                                  }
                                })
                              }}
                            /> Booking cancellation
                          </label>
                          <label htmlFor="contact-notifications-en_route">
                              <input type="checkbox" id="contact-notifications-en_route" 
                                checked={contact.notifications.en_route} 
                                onChange={(e) => {
                                  setContact({
                                    ...contact, 
                                    notifications: {
                                      ...contact.notifications,
                                      en_route: e.target.checked
                                    }
                                  })
                                }}
                              /> En route to pickup
                          </label>
                          <label htmlFor="contact-notifications-pickup_1_mile">
                              <input type="checkbox" id="contact-notifications-pickup_1_mile" 
                                checked={contact.notifications.pickup_1_mile}
                                onChange={(e) => {
                                  setContact({
                                    ...contact, 
                                    notifications: {
                                      ...contact.notifications,
                                      pickup_1_mile: e.target.checked
                                    }
                                  })
                                }}
                              /> 1 mile to pickup
                          </label>
                          <label htmlFor="contact-notifications-arrived_at_pickup">
                              <input type="checkbox" id="contact-notifications-arrived_at_pickup" 
                                checked={contact.notifications.arrived_at_pickup} 
                                onChange={(e) => {
                                  setContact({
                                    ...contact, 
                                    notifications: {
                                      ...contact.notifications,
                                      arrived_at_pickup: e.target.checked
                                    }
                                  })
                                }}
                              /> Arrived at pickup
                          </label>
                          <label htmlFor="contact-notifications-pob">
                              <input type="checkbox" id="contact-notifications-pob" 
                                checked={contact.notifications.pob} 
                                onChange={(e) => {
                                  setContact({
                                    ...contact, 
                                    notifications: {
                                      ...contact.notifications,
                                      pob: e.target.checked
                                    }
                                  })
                                }}
                              /> POB notifications by email 
                          </label>
                          <label htmlFor="contact-notifications-dropoff_1_mile">
                              <input type="checkbox" id="contact-notifications-dropoff_1_mile" 
                                checked={contact.notifications.dropoff_1_mile} 
                                onChange={(e) => {
                                  setContact({
                                    ...contact, 
                                    notifications: {
                                      ...contact.notifications,
                                      dropoff_1_mile: e.target.checked
                                    }
                                  })
                                }}
                              /> Dropoff 1 mile
                          </label>
                          <label htmlFor="contact-notifications-arrived_at_dropoff">
                              <input type="checkbox" id="contact-notifications-arrived_at_dropoff" 
                                checked={contact.notifications.arrived_at_dropoff}
                                onChange={(e) => {
                                  setContact({
                                    ...contact, 
                                    notifications: {
                                      ...contact.notifications,
                                      arrived_at_dropoff: e.target.checked
                                    }
                                  })
                                }}
                              /> Arrived at dropoff
                          </label>
                          <label htmlFor="contact-notifications-pod">
                              <input type="checkbox" id="contact-notifications-pod" 
                                checked={contact.notifications.pod} 
                                onChange={(e) => {
                                  setContact({
                                    ...contact, 
                                    notifications: {
                                      ...contact.notifications,
                                      pod: e.target.checked
                                    }
                                  })
                                }}
                              /> POD notifications by email
                          </label>
                          {/* <br />
                          <label htmlFor="contact-notifications-mailshot">
                              <input type="checkbox" id="contact-notifications-mailshot" 
                                checked={contact.notifications.mailshot} 
                                onChange={(e) => {
                                  setContact({
                                    ...contact, 
                                    notifications: {
                                      ...contact.notifications,
                                      mailshot: e.target.checked
                                    }
                                  })
                                }}
                              /> News &amp; updates
                          </label> */}
                        </div>
                    </div>
                 </div>
                <div className="custom-row">
                    <div className="flex flex-col">
                        <label className="title font-light mb-0.5">Notes</label>
                        <input 
                          type="text" 
                          maxlength="65535"
                          value={details.customer_notes}
                          onChange={(e) => setDetails({...details, customer_notes: e.target.value})}
                          className="text-input rounded-md" 
                          placeholder="Any additional details to help your delivery driver...." 
                          autoComplete="off"
                          onFocus={(e) => {
                            e.target.autocomplete = "details-customer-notes"
                          }}
                        />
                    </div>
                </div>

                  {/* Pickups */}
                  <div className="w-full">
                    { pickups.map((p, index) => {
                      return (
                        <React.Fragment key={index}>
                          <h2 className="text-xl font-medium mb-3"> { index == 0 ? 'Collection Address' : `Stop Address (Collection)`}</h2>
                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Company</label>
                              <input 
                                type="text"
                                maxlength="60"
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={p.address.company}
                                onChange={(e) => {
                                  const tempPickups = [...pickups]
                                  tempPickups[index].address.company = e.target.value 
                                  setPickups(tempPickups)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `address-company-${index}`
                                }}
                              />
                          </div>

                          <div className="sm:flex custom-row">
                            <div className="flex flex-col sm:w-1/2 sm:pr-2">
                                <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span></label>
                                <input 
                                  type="text" 
                                  maxlength="20"
                                  value={p.contact.name.first} 
                                  onChange={(e) => {
                                    const tempPickups = [...pickups]
                                    tempPickups[index].contact.name.first = e.target.value
                                    setPickups(tempPickups)
                                  }} 
                                  className="text-input rounded-md" 
                                  autoComplete="off"
                                  onFocus={(e) => {
                                    e.target.autocomplete = `contact-name-first-${index}`
                                  }}
                                />
                                
                            </div>
                            <div className="flex flex-col sm:w-1/2 sm:pl-2">
                                <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span></label>
                                <input 
                                  type="text" 
                                  maxlength="20"
                                  value={p.contact.name.last}
                                  onChange={(e) => {
                                    const tempPickups = [...pickups]
                                    tempPickups[index].contact.name.last = e.target.value
                                    setPickups(tempPickups)
                                  }} 
                                  className="text-input rounded-md" 
                                  autoComplete="off"
                                  onFocus={(e) => {
                                    e.target.autocomplete = `contact-name-last-${index}`
                                  }}
                                />
                            </div>
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Contact Telephone <span className="text-red-500">*</span></label>
                              <input 
                                type="tel"
                                maxlength="20"
                                min="0"
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={p.contact.contact_details.phone}
                                onChange={(e) => {
                                  const tempPickups = [...pickups]
                                  tempPickups[index].contact.contact_details.phone = e.target.value 
                                  setPickups(tempPickups)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `contact-details-phone-${index}`
                                }}
                              />
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Address line 1 <span className="text-red-500">*</span></label>
                              <input 
                                type="text"
                                maxlength="100"
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={p.address.address_1}
                                onChange={(e) => {
                                  const tempPickups = [...pickups]
                                  tempPickups[index].address.address_1 = e.target.value 
                                  setPickups(tempPickups)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `address-address-1-${index}`
                                }}
                              />
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Address line 2</label>
                              <input 
                                type="text"
                                maxlength="100" 
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={p.address.address_2}
                                onChange={(e) => {
                                  const tempPickups = [...pickups]
                                  tempPickups[index].address.address_2 = e.target.value 
                                  setPickups(tempPickups)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `address-address-2-${index}`
                                }}
                              />
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">City <span className="text-red-500">*</span></label>
                              <input 
                                type="text"
                                maxlength="40" 
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={p.address.city}
                                onChange={(e) => {
                                  const tempPickups = [...pickups]
                                  tempPickups[index].address.city = e.target.value 
                                  setPickups(tempPickups)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `address-city-${index}`
                                }}
                              />
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Postcode</label>
                              <input 
                                type="text"
                                maxlength="10" 
                                className="text-input rounded-md input-bg-gray" 
                                placeholder="" 
                                readOnly
                                disabled
                                value={p.address.postcode}
                              />
                              <a className="text-green-primary text-base mt-2" style={{ color: "#267FCA" }} href="/">Edit postcode</a>
                          </div>
                        </React.Fragment>
                      )
                    }) }
                  </div>
                  {/* End Pickups */}

                  {/* Dropoffs */}
                  <div className="w-full">
                    { dropoffs.map((d, index) => {
                      return (
                        <React.Fragment key={index}>
                          <h2 className="text-xl font-medium mb-3"> { index == 0 ? 'Delivery Address' : `Stop Address (Delivery)`}</h2>
                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Company</label>
                              <input 
                                type="text"
                                maxlength="60" 
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={d.address.company}
                                onChange={(e) => {
                                  const tempDropoffs = [...dropoffs]
                                  tempDropoffs[index].address.company = e.target.value 
                                  setDropoffs(tempDropoffs)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `address-company-${index}`
                                }}
                              />
                          </div>

                          <div className="sm:flex custom-row">
                            <div className="flex flex-col sm:w-1/2 sm:pr-2">
                                <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span></label>
                                <input 
                                  type="text" 
                                  maxlength="20"
                                  value={d.contact.name.first} 
                                  onChange={(e) => {
                                    const tempDropoffs = [...dropoffs]
                                    tempDropoffs[index].contact.name.first = e.target.value
                                    setDropoffs(tempDropoffs)
                                  }} 
                                  className="text-input rounded-md" 
                                  autoComplete="off"
                                  onFocus={(e) => {
                                    e.target.autocomplete = `contact-name-first-${index}`
                                  }}
                                />
                                
                            </div>
                            <div className="flex flex-col sm:w-1/2 sm:pl-2">
                                <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span></label>
                                <input 
                                  type="text" 
                                  maxlength="20"
                                  value={d.contact.name.last}
                                  onChange={(e) => {
                                    const tempDropoffs = [...dropoffs]
                                    tempDropoffs[index].contact.name.last = e.target.value
                                    setDropoffs(tempDropoffs)
                                  }} 
                                  className="text-input rounded-md" 
                                  autoComplete="off"
                                  onFocus={(e) => {
                                    e.target.autocomplete = `contact-name-last-${index}`
                                  }}
                                />
                            </div>
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Contact Telephone <span className="text-red-500">*</span></label>
                              <input 
                                type="tel"
                                maxlength="20"
                                min="0"
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={d.contact.contact_details.phone}
                                onChange={(e) => {
                                  const tempDropoffs = [...dropoffs]
                                  tempDropoffs[index].contact.contact_details.phone = e.target.value 
                                  setDropoffs(tempDropoffs)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `contact-details-phone-${index}`
                                }}
                              />
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Address line 1 <span className="text-red-500">*</span></label>
                              <input 
                                type="text"
                                maxlength="100"
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={d.address.address_1}
                                onChange={(e) => {
                                  const tempDropoffs = [...dropoffs]
                                  tempDropoffs[index].address.address_1 = e.target.value 
                                  setDropoffs(tempDropoffs)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `address-1-${index}`
                                }}
                              />
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Address line 2</label>
                              <input 
                                type="text"
                                maxlength="100"
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={d.address.address_2}
                                onChange={(e) => {
                                  const tempDropoffs = [...dropoffs]
                                  tempDropoffs[index].address.address_2 = e.target.value 
                                  setDropoffs(tempDropoffs)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `address-2-${index}`
                                }}
                              />
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">City <span className="text-red-500">*</span></label>
                              <input 
                                type="text"
                                maxlength="40" 
                                className="text-input rounded-md" 
                                placeholder="" 
                                value={d.address.city}
                                onChange={(e) => {
                                  const tempDropoffs = [...dropoffs]
                                  tempDropoffs[index].address.city = e.target.value 
                                  setDropoffs(tempDropoffs)
                                }}
                                autoComplete="off"
                                onFocus={(e) => {
                                  e.target.autocomplete = `address-city-${index}`
                                }}
                              />
                          </div>

                          <div className="flex flex-col custom-row">
                              <label className="title font-light mb-0.5">Postcode</label>
                              <input 
                                type="text"
                                maxlength="10" 
                                className="text-input rounded-md input-bg-gray" 
                                placeholder="" 
                                readOnly
                                disabled
                                value={d.address.postcode}
                              />
                              <a className="text-base mt-2" style={{ color: "#267FCA" }} href="/">Edit postcode</a>
                          </div>
                        </React.Fragment>
                      )
                    }) }
                  </div>
                  {/* End Dropoffs */}
            </form>

            <div className="flex flex-col">
                  <div>
                    <input type="checkbox" className="mr-2" id="tos" checked={tos} onChange={(e) => setTos(e.target.checked)} style={{ height: 'auto' }}/>
                    <label htmlFor="tos">
                        I have read and accepted the <a href={company.terms_and_conditions} target="_blank" className="text-green-primary text-base" style={{ color: "#267FCA" }}>T&Cs</a><span className="text-red-500"> *</span>
                    </label>
                  </div>
                          
                  <div>
                    <input type="checkbox" className="mr-2" id="loading_notice" checked={loadingNotice} onChange={(e) => setLoadingNotice(e.target.checked)} style={{ height: 'auto' }}/>
                    <label htmlFor="loading_notice">
                        I have read and agree to the loading notice below<span className="text-red-500"> *</span>
                    </label>
                  </div>
                  
                  
                  <div className="blue-checkbox sm:flex items-center p-4 rounded-lg mt-5">
                    <input type="checkbox" className="mr-4 blue-cb" checked={return_journey} id="blue-cb" value={return_journey} onChange={(e) => setReturnJourney(e.target.checked)}/>
                    <label htmlFor="blue-cb">
                        <p className="text-white font-bold">Would you like a return journey? 
                          <span className="font-light"> Check the box to add a return journey to your booking. </span>
                        </p>
                    </label>
                  </div>
                  <div className="blue-checkbox sm:flex items-center p-4 rounded-lg mt-5">

                    <input
                      type="checkbox"
                      className="mr-4 blue-cb"
                      id="contact-notifications-mailshot"
                      checked={contact.notifications.mailshot}
                      onChange={(e) => {
                        setContact({
                          ...contact, 
                          notifications: {
                            ...contact.notifications,
                            mailshot: e.target.checked
                          }
                        })
                      }}/>
                    <label htmlFor="blue-cb">
                        <p className="text-white font-bold">Sign Up To Newsletters? 
                          <span className="font-light"> We ocassionally send out newsletters including promotions, if you would like to receive these check the box to opt in. </span>
                        </p>
                    </label>
                  </div>
            </div>

            <div className="payment-box">
                <div className="sm:flex items-center justify-between mt-5 mb-2">
                    <div className="flex items-center">
                        <h2 className="text-xl font-medium mr-5"> Payment Details</h2>
                        <img src={cardsIcon} className="mr-2" />
                    </div>
                    <img src={stripeIcon} className="mr-2 mt-5 sm:mt-0" />
                </div>

                <div className="mt-4 mb-4">
                  

                  <hr className="mt-5" />

                  
                  <h1 className="mt-4 mb-4 flex items-center" style={{fontWeight: '900', fontSize: '40px'}}>£{parseFloat(getTotal()).toFixed(2)} <span className="text-sm font-light ml-3">(inc VAT)</span> </h1> 
                  

                  { errors.length > 0 && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                      { errors.map((e) => {
                        return (
                          <li className="text-red-500">{e}</li>
                        )
                      })}
                    </div>
                  )}

                  <button onClick={updateQuote} disabled={updating} className="custom-green-button default-bg-color">{updating ? 'Redirecting...' : 'Book Now'}</button>
                    
                  { out_of_hours.amount > 0 && (
                    <div class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 my-4">
                    <p class="font-bold">Out of hours (+ £{parseFloat(out_of_hours.amount).toFixed(2)})</p>
                    <p>{out_of_hours.message}</p>
                  </div>
                  )}
                  
                  { return_journey && (
                    <div class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 my-4">
                      <p class="font-bold">Return journey</p>
                      <p>There is an extra charge of {`(£${parseFloat(total.return_journey_price).toFixed(2)})`} because you have opted to have a return journey.</p>
                    </div>
                  )}

                  <div className="mt-8 lg:flex items-center rounded-lg p-6 md:p-12 payment-notice" style={{ backgroundColor: "#101D3B", color: 'white' }}>
                    <img src={deliveryIcon} className="mr-10" />
                    <div className="">
                      <h3 className="text-xl font-bold mt-2 lg:mt-0" style={{ color: 'white' }}>Loading & Unloading Notice</h3>
                      <p className="md:text-lg leading-6 font-light mt-3 loading-notes">{company.loading_notes}</p>
                    </div>
                  </div>
                </div>

            </div>
        </div>

        <div className="right-side pt-2 mt-10 md:mt-0">
            <div className="dark-box rounded-lg p-6">
                {/* <div className="flex items-center">
                    <p className="text-green-primary font-medium">Collection within 1 hour available</p>
                </div> */}

                { quote.booking.pickups.map((pickup, index) => {
                  return (
                    <div className="mt-3 content pl-9">
                      <h2 className="text-white text-lg font-medium mb-0.5 title relative"> Collection { index > 0 ? `(stop ${index})` : `` }</h2>
                      <p className="text-white font-light">{ getPickupDetails(pickup).time }</p>
                      <p className="text-white font-light">{ getPickupDetails(pickup).date }</p>
                      <p className="text-white font-light">{ pickup.address.postcode }</p>
                    </div>
                  )
                })}

                { quote.booking.dropoffs.map((dropoff, index) => {
                  return (
                    <div className="mt-6 content pl-9">
                      <h2 className="text-white text-lg font-medium mb-0.5 title relative">{ index == (quote.booking.dropoffs.length - 1) ? 'Delivery' : `Delivery (stop ${index + 1})` }</h2>
                      <p className="text-white font-light">{ getDropoffDetails(dropoff).time }</p>
                      <p className="text-white font-light">{ getDropoffDetails(dropoff).date }</p>
                      <p className="text-white font-light">{ dropoff.address.postcode }</p>
                    </div>
                  )
                })}
                
                <div className="text-center mt-5">
                    <a className="text-green-primary text-lg text-center" style={{ color: "#267FCA"}} href={`/quote/step-2?quote=${quote.id}`}>Edit</a>
                </div>
            </div>



            <div><div className="shadow-2xl rounded-lg py-7 box mt-5 mb-8">
                    <img src={quote.quote.image} className="image" />

                    <h4 className="uppercase font-bold text-xl text-center mt-3">{ quote.quote.name }</h4>

                    <div className="px-6 pt-3 mt-2 pb-2 custom-border">
                        <div className="flex justify-between py-0.5">
                            <h5 className="text-md font-light custom-gray">Length</h5>
                            <h5 className="text-md font-light custom-gray">{ parseDescription(quote.quote.description).length }</h5>
                        </div>
                        <div className="flex justify-between py-0.5">
                            <h5 className="text-md font-light custom-gray">Width</h5>
                            <h5 className="text-md font-light custom-gray">{ parseDescription(quote.quote.description).width }</h5>
                        </div>
                        <div className="flex justify-between py-0.5">
                            <h5 className="text-md font-light custom-gray">Height</h5>
                            <h5 className="text-md font-light custom-gray">{ parseDescription(quote.quote.description).height }</h5>
                        </div>
                        <div className="flex justify-between py-0.5">
                            <h5 className="text-md font-light custom-gray">Payload</h5>
                            <h5 className="text-md font-light custom-gray text-right">
                              <div dangerouslySetInnerHTML={{ __html: parseDescription(quote.quote.description).payload }} />
                            </h5>
                        </div>
                    </div>

                    <div className="px-6 pt-3">
                        <div className="flex justify-between py-0.5">
                            <h5 className="text-md">Price</h5>
                            <h5 className="text-md">£{parseFloat(quote.quote.price).toFixed(2)}</h5>
                        </div>

                        { return_journey && (
                          <div className="flex justify-between py-0.5">
                            <h5 className="text-md">Return Journey</h5>
                            <h5 className="text-md">£{parseFloat(total.return_journey_price).toFixed(2)}</h5>
                          </div>
                        )}

                        
                        { total.out_of_hours_price > 0 && (
                          <div className="flex justify-between py-0.5">
                            <h5 className="text-md">Out of Hours</h5>
                            <h5 className="text-md">£{parseFloat(total.out_of_hours_price).toFixed(2)}</h5>
                          </div>
                        ) }

                        <div className="flex justify-between py-0.5">
                            <h5 className="text-md">VAT</h5>
                            <h5 className="text-md">£{
                              return_journey ? (
                                <>{((parseFloat(total.quote_price) + parseFloat(total.return_journey_price) + parseFloat(total.out_of_hours_price)) * 0.2).toFixed(2)}</>
                              ) : (
                                <>{((parseFloat(total.quote_price) + parseFloat(total.out_of_hours_price)) * 0.2).toFixed(2)}</>
                              )
                            }</h5>
                        </div>

                        <div className="flex justify-between py-0.5">
                            <h5 className="text-lg uppercasee">TOTAL</h5>
                            <h5 className="text-lg font-bold">£{parseFloat(getTotal()).toFixed(2)}</h5>
                        </div>
                    </div>

                    <a href={`/quote/step-2?quote=${quote.id}`} className="custom-green-button">Change Vehicle</a>
                </div>
              </div>
            

            <div className="additional-stops-container">
                <img src={lineIcon} />
                <h3 >Additional Stops</h3>
                <p>
                  Additional stops will be managed & planned once the booking is confirmed. We will call you back on the details provided to confirm these times with you.
                  <br />
                  <br />
                  
                  Call: <b>{company.info["phone"]}</b>
                </p>
            </div>
        </div>
    </section>
    </React.Fragment>
  )
}

export default PagePayment;