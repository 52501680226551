import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {
  EmbedContainer,
  FormWrapper,
  FormInputFields, 
  FormSubmitButton,
  FromField, 
  ToField,
  Icon1, Icon2, Icon3,
  FromInput,
  ToInput,
  EmailField,
  EmailInput
} from './style'

const BOOKING_ROOT_APP = "https://jyst-booking.herokuapp.com"
// const BOOKING_ROOT_APP = "http://lvh.me:3000"
// https://medium.com/better-programming/the-best-practice-with-google-place-autocomplete-api-on-react-939211e8b4ce
let autoCompleteFrom;
let autoCompleteTo;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}

function handleScriptLoad(updateQuery, autoCompleteRef, field) {

  if(field === "from") {
    autoCompleteFrom = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["geocode"], componentRestrictions: { country: "uk" } }
    );

    autoCompleteFrom.setFields(["address_components", "formatted_address"]);
    autoCompleteFrom.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery, field)
    );
  } else {
    autoCompleteTo = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["geocode"], componentRestrictions: { country: "uk" } }
    );

    autoCompleteTo.setFields(["address_components", "formatted_address"]);
    autoCompleteTo.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery, field)
    );
  }
}

async function handlePlaceSelect(updateQuery, field) {
  if (field === "from") {
    const addressObject = autoCompleteFrom.getPlace();
    updateQuery(getPostcode(addressObject));
  } else {
    const addressObject = autoCompleteTo.getPlace();
    updateQuery(getPostcode(addressObject));
  }

}

function getPostcode(address_object) {
  const { address_components } = address_object
  var postcode = ""
  address_components.forEach(({ short_name, types }) => {
    console.log("SHORT NAME: ", short_name)
    console.log("TYPES: ", types)
    types.forEach((type) => {
      if(type === "postal_code") {
        console.log("FOUND POSTAL CODE: ", short_name)
        postcode = short_name
      }
    })
  })

  return postcode
}

const GetQuote = ({ company }) => {
  const [loading, setLoading] = useState(false)
  const [bookingsUrl, setBookingsUrl] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")

  const toRef = useRef(null)
  const fromRef = useRef(null)

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAh6JdJQ98RdQwgi6Ey1swIoQ7cvHIyoko&libraries=places`,
      () => {
        handleScriptLoad(setFrom, fromRef, "from")
        handleScriptLoad(setTo, toRef, "to")
      }
    );
  }, [])

  return (
    <EmbedContainer>
      <form action={`${company.bookings_url}/quote/step-2`} method="get" autoComplete="off">
        <FormWrapper>
            <FormInputFields>
              <FromField>
                <Icon1 
                  src={`${BOOKING_ROOT_APP}/icons/home-icon-1.svg`}
                />
                <FromInput 
                  type="text"
                  name="from"
                  ref={fromRef}
                  value={from} 
                  onChange={(e) => setFrom(e.target.value)}  
                  placeholder="Pickup Postcode"
                />
              </FromField>

              <ToField>
                <Icon2 
                  src={`${BOOKING_ROOT_APP}/icons/home-icon-2.svg`}
                />
                <Icon3 
                  src={`${BOOKING_ROOT_APP}/icons/home-icon-3.svg`}
                />
                <ToInput 
                  type="text"
                  name="to"
                  ref={toRef}
                  value={to} 
                  onChange={(e) => setTo(e.target.value)}  
                  placeholder="Destination Postcode"
                />
              </ToField>

              <EmailField>
                <Icon1 
                  src={`${BOOKING_ROOT_APP}/icons/home-icon-4.svg`}
                />
                <EmailInput
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                />
              </EmailField>
              
            </FormInputFields>

            <FormSubmitButton
              type="submit"          
              disabled={loading}
              value="Get A Quote Online Now"
            />

        </FormWrapper>
      </form>
    </EmbedContainer>
  )
}

export default GetQuote