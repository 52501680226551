import React from 'react'
import styled from 'styled-components'

export const GetQuoteButton = styled.input`
  background-color: #29A604;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 8px;
  cursor: pointer;
  height: 65px !important;
`