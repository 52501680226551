import React from 'react'
import { VAT, parseDescription } from "../../../../utils"

const QuoteItem = ({ quote, company, selectQuote, error }) => {

  return (
    <div className="shadow-2xl rounded-lg py-7 box mb-8">
      <img src={quote.image} className="image vehicle-image" />
      <h4 className="uppercase font-bold text-xl text-center mt-3">{ quote.name }</h4>

      <div className="px-6 pt-3 mt-2 pb-2 custom-border">
          <div className="flex justify-between py-0.5">
              <h5 className="text-md font-light custom-gray">Length</h5>
              <h5 className="text-md font-light custom-gray">{ parseDescription(quote.description).length }</h5>
          </div>
          <div className="flex justify-between py-0.5">
              <h5 className="text-md font-light custom-gray">Width</h5>
              <h5 className="text-md font-light custom-gray">{ parseDescription(quote.description).width }</h5>
          </div>
          <div className="flex justify-between py-0.5">
              <h5 className="text-md font-light custom-gray">Height</h5>
              <h5 className="text-md font-light custom-gray">{ parseDescription(quote.description).height }</h5>
          </div>
          <div className="flex justify-between py-0.5">
              <h5 className="text-md font-light custom-gray">Payload</h5>
              <h5 className="text-md font-light custom-gray text-right">
                <div dangerouslySetInnerHTML={{ __html: parseDescription(quote.description).payload }} />
              </h5>
          </div>
      </div>

      <div className="px-6 pt-3">
          <div className="flex justify-between py-0.5">
              <h5 className="text-md">Price</h5>
              <h5 className="text-md">£{parseFloat(quote.price).toFixed(2)}</h5>
          </div>
          <div className="flex justify-between py-0.5">
              <h5 className="text-md">VAT</h5>
              <h5 className="text-md">£{VAT(quote.price).vat}</h5>
          </div>
          <div className="flex justify-between py-0.5">
              <h5 className="text-lg uppercasee">TOTAL</h5>
              <h5 className="text-lg font-bold">£{VAT(quote.price).total}</h5>
          </div>
      </div>
      <button disabled={error}  onClick={() => { selectQuote(quote) }} className="custom-green-button w-full default-bg-color" style={{ cursor: "pointer" }}>Choose this option</button>
    </div>
  )
}

export default QuoteItem;