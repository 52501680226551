import styled from 'styled-components'

export const EmbedContainer = styled.div`
  background-color: white;
  padding: 15px;
`

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const FormInputFields = styled.div`
  border: 1px solid #CFD1D7;
  border-radius: 8px;
  padding: 2px;
  display: flex;
  width: 73.5%;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

export const FormSubmitButton = styled.input`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 8px;
  background-color: #29A604;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  width: 25%;
  height: 60px;
  border: none;

  @media only screen and (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
    height: 55px;
  }
`

export const FromField = styled.div`
  width: 33%;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const ToField = styled.div`
  width: 33%;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const EmailField = styled.div`
  width: 34%;
  position: relative;
  border-left: 1px solid #CFD1D7;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const Icon1 = styled.img`
  position: absolute;
  top: 18px;
  left: 15px;
`

export const Icon2 = styled.img`
  position: absolute;
  top: 18px;
  left: 0;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

export const Icon3 = styled.img`
  position: absolute;
  top: 18px;
  left: 40px;

  @media only screen and (max-width: 600px) {
    left: 17px;
  }
`

export const FromInput = styled.input`
  height: 60px;
  border: none;
  padding-left: 50px;
  width: 100%;
  &:focus {
    outline: none;
  }
`

export const ToInput = styled.input`
  height: 60px;
  border: none;
  padding-left: 75px;
  width: 100%;
  &:focus {
    outline: none;
  }
  
  @media only screen and (max-width: 600px) {
    padding-left: 50px;
  }
`

export const EmailInput = styled.input`
  height: 60px;
  border: none;
  padding-left: 50px;
  width: 100%;
  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 600px) {
    padding-left: 50px;
  }
`