import styled from 'styled-components'

export const MultistopContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
`

export const FormWrapper = styled.div`
  background-color: white;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;

`

export const CollectionFieldGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #CFD1D7;
`

export const Icon = styled.img`
  height: 22px;
  width: 22px;
  margin: 17px;
`

export const CollectionInputField = styled.input`
  height: 60px;
  border: none;
  border-radius: 8px;
  width: 100%;
  padding-right: 20px;
  &:focus {
    outline: none;
  }
`

export const AddStopGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const AddStopLabel = styled.div`
  color: #267FCA;
`

export const AddStopButton = styled.button`
  background-color: #267FCA;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
`

export const StopGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 17px;
  padding-right: 17px;
`

export const StopInputField = styled.input`
  height: 60px;
  border: none;
  flex: 1;
  padding-right: 20px;
  &:focus {
    outline: none;
  }
`

export const StopItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 17px;
`

export const StopInput = styled.input`
  flex: 1;
  border: none;
  &:focus {
    outline: none;
  }
`

export const StopType = styled.div`
  background-color: #09141B;
  padding: 2px 4px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  margin-right: 16px;
  margin-left: 16px;
`

export const StopIcon = styled.img`
  height: 16px;
  width: 16px;
  cursor: pointer;
`

export const StopSelect = styled.select`
  color: #878D9A;
  margin-right: 17px;
  &:focus {
    outline: none;
  }
`

export const DestinationFieldGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #CFD1D7;
`

export const DestinationInputField = styled.input`
  height: 60px;
  border: none;
  width: 100%;
  padding-right: 20px;
  &:focus {
    outline: none;
  }
`

export const EmailFieldGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const EmailInputField = styled.input`
  height: 60px;
  border: none;
  width: 100%;
  border-radius: 8px;
  &:focus {
    outline: none;
  }
`

export const FormSubmitButton = styled.input`
  font-size: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-style: normal;
  line-height: 22.4px;
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  background-color: #29A604;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  width: 100%;
  height: 62px;
  border: none;
  margin-top: 69px;
`