import React from 'react'
import ReactJson from 'react-json-view'

const JsonViewer = ({ json }) => {

  return (
    <React.Fragment>
      <ReactJson 
        src={json} 
        theme="monokai"
      />
      
    </React.Fragment>
  )
}

export default JsonViewer;