import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { GetQuoteButton } from './style'

const Demo = ({}) => {
  const [from, setFrom] = useState("")
  const [email, setEmail] = useState("")

  const [to, setTo] = useState("")
  const [url, setUrl] = useState("")
  const [domain, setDomain] = useState("")
  const [res, setRes] = useState()
  const onClickHandle = () => {
    console.log("YOU CLICK ON THE BUTTON!")
    setUrl(`https://booking.domain.com?from=${from}&to=${to}`)
  }

  useEffect(() => {
    setDomain(window.location.href)
    axios.get('https://jyst-booking.herokuapp.com/api/v1/company', {
      params: {
        source: window.location.href
      }
    }).then((response) => {
      console.log("RESPONSE: ", response.data)
      setRes(response.data)
    }).catch((error) => {
      console.log("Error: ", error)
    })
    
  }, [])

  return (
    <React.Fragment>
        <h1>BOOKING FORM site: {domain}</h1>
        { url && (
          <p>{`Traveling from ${from} to ${to}`}</p>
        )}
        <p>
          API RESPONSE: <br/>
          { JSON.stringify(res) }
        </p>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

        <input type="text" value={from} onChange={(e) => setFrom(e.target.value)} />
        <input type="text" value={to} onChange={(e) => setTo(e.target.value)} />
        <GetQuoteButton type="submit" onClick={onClickHandle} value="Get A Quote Online Now" />
    </React.Fragment>
  )
}

export default Demo;