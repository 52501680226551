// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Import CSS
import "../stylesheets/application";

import WebpackerReact     from 'webpacker-react';
import axios              from 'axios';
import Demo               from '../components/demo';
import GetQuote           from '../components/get-quote';
import JsonViewer         from '../components/json-viewer';
import MultistopQuote     from '../components/multistop-quote';

// pages
import PageQuote          from '../components/page-quote';
import PagePayment        from '../components/page-payment';


WebpackerReact.setup({
  Demo,
  GetQuote,
  JsonViewer,
  PageQuote,
  PagePayment,
  MultistopQuote,
})

// Add CSRF token
const csrfToken = document.querySelector("meta[name=csrf-token]").content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
