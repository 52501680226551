import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import validator from 'validator'
import { getAddress } from "../../utils"
import {
  MultistopContainer,
  FormWrapper,
  Icon,
  
  CollectionFieldGroup,
  CollectionInputField,

  StopGroup,
  StopInputField,

  DestinationFieldGroup,
  DestinationInputField,

  EmailFieldGroup,
  EmailInputField,

  FormSubmitButton,

  StopItem,
  StopType,
  StopIcon,
  StopInput,
  StopSelect,

  AddStopGroup,
  AddStopLabel,
  AddStopButton
} from './style'

const BOOKING_ROOT_APP = "https://jyst-booking.herokuapp.com"
// const BOOKING_ROOT_APP = "http://book.lvh.me:3000"

let autocompleteCollection;
let autocompleteDelivery;
let autocompleteStops;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}

const handleScriptLoad = (updateQuery, autocompleteRef, field) => {
  switch(field) {
    case "collection": {
      autocompleteCollection = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        { componentRestrictions: { country: "uk"} }
      )
      autocompleteCollection.setFields(["address_components", "formatted_address"])
      autocompleteCollection.addListener("place_changed", () => {
        handlePlaceSelect(updateQuery, autocompleteCollection, field)
      })
      break;
    }
    case "delivery": {
      autocompleteDelivery = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        { componentRestrictions: { country: "uk"} },
      )
      autocompleteDelivery.setFields(["address_components", "formatted_address"])
      autocompleteDelivery.addListener("place_changed", () => {
        handlePlaceSelect(updateQuery, autocompleteDelivery, field)
      })
      break;
    }
    case "stops": {
      autocompleteStops = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        { componentRestrictions: { country: "uk"}
        }

      )
      autocompleteStops.setFields(["address_components", "formatted_address"])
      autocompleteStops.addListener("place_changed", () => {
        handlePlaceSelect(updateQuery, autocompleteStops, field)
      })
      break;
    }
  }
}

const handlePlaceSelect = async (updateQuery, autocompleteRef, field) => {
  const address = autocompleteRef.getPlace()

  const { address_1, city, postcode, formatted_address } = getAddress(address)
  
  switch(field) {
    case "collection": {
      updateQuery({
        address_1,
        city,
        postcode,
        formatted_address,
        type: "pickup"
      })
      break;
    }
    case "delivery": {
      updateQuery({
        address_1,
        city,
        postcode,
        formatted_address,
        type: "dropoff"
      })
      break;
    }
    case "stops": {
      updateQuery({
        address_1, 
        city, 
        postcode, 
        formatted_address
      })
      break;
    }
  }
  
  
}


const MultistopQuote = ({ company }) => {
  const [collection, setCollection] = useState(null)
  const [delivery, setDelivery] = useState(null)
  const [tempStop, setTempStop] = useState(null)

  const [email, setEmail] = useState("")

  const [stops, setStops] = useState([])
  const [stopType, setStopType] = useState("pickup")

  const [addStopVisible, setAddStopVisible] = useState(false)

  const [processing, setProcessing] = useState(false)

  const collectionRef = useRef(null)
  const deliveryRef = useRef(null)
  const stopsRef = useRef(null)
  const stopTypeRef = useRef(null)

  const [error, setError] = useState()

  // useEffect(() => {
  //   if(tempStop) {
  //     const lastStop = stops[stops.length -1]

  //     if(lastStop && tempStop.type === "pickup" && lastStop.type === "dropoff") {
  //       console.log("INVALID CHOSE!")
  //     } 
  //     // else {
  //     //   setStops([
  //     //     ...stops,
  //     //     tempStop
  //     //   ])
  //     // }
      
  //     // stopsRef.current.value = ""
  //   }

  // }, [tempStop])

  const removeStop = (index) => {
    const tmp = [...stops]
    tmp.splice(index, 1)
    setStops(tmp)
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAh6JdJQ98RdQwgi6Ey1swIoQ7cvHIyoko&libraries=places`,
      () => {
        handleScriptLoad(setCollection, collectionRef, "collection")
        handleScriptLoad(setDelivery, deliveryRef, "delivery")
      }
    )
  }, [])

  const onSubmitQuote = () => {
    setProcessing(true)
    
    let from = collection
    let to = delivery

    if(!from) {
      from = {
        postcode: collectionRef.current.value, 
        address_1: "",
        city: "",
        formatted_address: "",
        type: "pickup"
      }

      if(!collectionRef.current.value) {
        setError("Collection Postcode can't be blank!")
        setProcessing(false)
        return true
      }
    }

    if(!to) {
      to = {
        postcode: deliveryRef.current.value,
        addres_1: "",
        city: "",
        formatted_address: "",
        type: "dropoff"
      }

      if (!deliveryRef.current.value) {
        setError("Destination Postcode can't be blank!")
        setProcessing(false)
        return(true)
      }
    }

    // is email empty
    if(!email) {
      setError("Email field can't be blank!")
      setProcessing(false)
      return
    } else {
      setError(null)
    }

    // is email valid
    if(!validator.isEmail(email)) {
      setError("Email is not valid!")
      setProcessing(false)
      return
    } else {
      setError(null)
    }
    

    let allStops = [
      from,
      ...stops,
      to
    ]

    axios.post(`/api/v1/companies/${company.id}/quotes`, {
      email,
      stops: allStops
    }).then((response) => {

      const { quote, error } = response.data

      if(quote) {
        window.location.href = `/quote/step-2?quote=${quote.id}`
      } else {
        setError(error)
      }

      setProcessing(false)
    }).catch((error) => {
      setProcessing(false)
    })

    
  }

  const handleStopTypeChanged = (e) => {
    setStopType(e.target.value)
  }

  useEffect(() => {
    if(addStopVisible)
      handleScriptLoad(setTempStop, stopsRef, "stops")
  }, [addStopVisible])

  const onAddNewStop = () => {
    
    if(tempStop) {
      // selected from google dropdown
      const lastStop = stops[stops.length -1]

      if(!(lastStop && stopType === "pickup" && lastStop.type === "dropoff")) {
        setStops([
          ...stops,
          {...tempStop, type: stopType}
        ])
        setAddStopVisible(false)
        setTempStop(null)
        stopsRef.current.value = ""
      } 
    } else if (stopsRef.current.value) {
      // postcode added manually
      
      const lastStop = stops[stops.length -1]

      if(!(lastStop && stopType === "pickup" && lastStop.type === "dropoff")) {
        
        setStops([
          ...stops,
          { address_1: "", city: "", postcode: stopsRef.current.value, formatted_address: stopsRef.current.value, type: stopType }
        ])
        setAddStopVisible(false)
        setTempStop(null)
        stopsRef.current.value = ""
      } 
    }
  }

  return (
    <MultistopContainer>
      {/* <form action={`${company.bookings_url}/quote/step-2`} method="get" autoComplete="off"> */}
        <FormWrapper>
          <CollectionFieldGroup>
              <Icon 
                  src={`${BOOKING_ROOT_APP}/icons/home-icon-1.svg`}
              />
              <CollectionInputField 
                type="text"
                placeholder="Collection Postcode..."
                ref={collectionRef}
                autoComplete="off"
                onFocus={(e) => {
                  e.target.autocomplete = "collection-foobarbaz"
                }}
              />
          </CollectionFieldGroup>


          { stops.map((stop, index) => {
            return (
              <StopItem key={index}>
                <StopInput value={stop.formatted_address} readOnly />
                <StopType>{ stop.type  }</StopType> 
                <StopIcon 
                  src={`${BOOKING_ROOT_APP}/icons/home-icon-6.svg`}
                  onClick={() => removeStop(index)}
                />
              </StopItem>
            )
          })}

          
          { addStopVisible && (
            <StopGroup>
              <StopSelect
                value={stopType} 
                onChange={handleStopTypeChanged} 
                ref={stopTypeRef}
              >
                <option value="pickup">pickup</option>
                <option value="dropoff">dropoff</option>
              </StopSelect>
              <StopInputField
                type="text"
                placeholder="Stop Postcode"
                ref={stopsRef}
                autoComplete="off"
                onFocus={(e) => {
                  e.target.autocomplete = "stops-foobarbaz"
                }}
              />
              <AddStopButton onClick={onAddNewStop}>Add Stop</AddStopButton>
            </StopGroup>
            )}

          { !addStopVisible && (
            <AddStopGroup onClick={() => setAddStopVisible(true)}>
              <Icon 
                src={`${BOOKING_ROOT_APP}/icons/home-icon-5.svg`}
              />
              <AddStopLabel>Add Another Stop</AddStopLabel>
            </AddStopGroup>
          ) }
          

          <DestinationFieldGroup>
            <Icon 
              src={`${BOOKING_ROOT_APP}/icons/home-icon-3.svg`}
            />
            <DestinationInputField
              type="text"
              placeholder="Destination Postcode..."
              ref={deliveryRef}
              autoComplete="off"
              onFocus={(e) => {
                e.target.autocomplete = "destination-foobarbaz"
              }}
            />
          </DestinationFieldGroup>

          <EmailFieldGroup>
            <Icon
              src={`${BOOKING_ROOT_APP}/icons/home-icon-4.svg`}
            />
            <EmailInputField
              type="email"
              placeholder="Your Email Address..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
              onFocus={(e) => {
                e.target.autocomplete = "email-foobarbaz"
              }}
            />
          </EmailFieldGroup>
        </FormWrapper>
        
        
        { error && (
          <p className="text-white text-center mt-2">
            {JSON.stringify(error)}
          </p>
        )}

        <FormSubmitButton 
          type="submit"
          value={processing ? "Building a Quote..." : "Get A Quote Online Now"}
          disabled={processing}
          onClick={onSubmitQuote}
        />
      {/* </form> */}
    </MultistopContainer>
  )
}

export default MultistopQuote;